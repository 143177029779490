import { Box } from "@chakra-ui/react";
import { getEnv, getToken } from "../../utils";
import { Autocomplete, Option } from "../Autocomplete";

const transformData = (data: any) => data.map((item: any) => ({
    key: item._id,
    label: item.name,
    value: item._id,
}) as Option);


export interface PatientSearchProps {
    onSelect: any;
}


const PatientSearch: React.FC<PatientSearchProps> = ({onSelect}) => {

    const searchPatient = (value: string): Promise<Option[]> => {
        const isLocal = getEnv().includes("localhost") || getEnv().includes("3000");
        const baseEnv = getEnv().replace(/https:\/\/next./, "");
        const token = getToken();
        let url = `https://api.${baseEnv}/api/patients/search?type=fullSearch&value=${value}`;
            if (isLocal) {
            url = `https://api.red.isbuildingluma.com/api/patients/search?type=fullSearch&value=${value}`;
        }
        const results = fetch(url, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
            },
        })
        .then((res) => res.json())
        .then(data => transformData(data));
        return results;
    }
    
    return (
        <Box 
            position="relative"
            marginLeft="50px"
        >
            <Autocomplete onSearch={searchPatient} onSelect={onSelect} />
        </Box>
    );
};

export default PatientSearch;
